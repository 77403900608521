/* tslint:disable */
/* eslint-disable */
/**
 * LoudHippo API
 * An API for LoudHippo Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@loudhippo.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DowngradePlanReasonViewModel } from '../models';
/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [plan] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingChangePlanGet: async (plan?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Billing/ChangePlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (plan !== undefined) {
                localVarQueryParameter['plan'] = plan;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingCustomerPortalGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Billing/CustomerPortal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {DowngradePlanReasonViewModel} [downgradePlanReasonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDowngradePlanReasonPost: async (apiVersion?: string, downgradePlanReasonViewModel?: DowngradePlanReasonViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Billing/DowngradePlanReason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downgradePlanReasonViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {string} [utmContent] 
         * @param {string} [utmCampaign] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingReturnFromStripeGet: async (sessionId?: string, utmContent?: string, utmCampaign?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Billing/ReturnFromStripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }

            if (utmContent !== undefined) {
                localVarQueryParameter['utm_content'] = utmContent;
            }

            if (utmCampaign !== undefined) {
                localVarQueryParameter['utm_campaign'] = utmCampaign;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [plan] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingChangePlanGet(plan?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingChangePlanGet(plan, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingCustomerPortalGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingCustomerPortalGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {DowngradePlanReasonViewModel} [downgradePlanReasonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDowngradePlanReasonPost(apiVersion?: string, downgradePlanReasonViewModel?: DowngradePlanReasonViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDowngradePlanReasonPost(apiVersion, downgradePlanReasonViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {string} [utmContent] 
         * @param {string} [utmCampaign] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingReturnFromStripeGet(sessionId?: string, utmContent?: string, utmCampaign?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingReturnFromStripeGet(sessionId, utmContent, utmCampaign, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [plan] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingChangePlanGet(plan?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.billingChangePlanGet(plan, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingCustomerPortalGet(apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.billingCustomerPortalGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {DowngradePlanReasonViewModel} [downgradePlanReasonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDowngradePlanReasonPost(apiVersion?: string, downgradePlanReasonViewModel?: DowngradePlanReasonViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.billingDowngradePlanReasonPost(apiVersion, downgradePlanReasonViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {string} [utmContent] 
         * @param {string} [utmCampaign] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingReturnFromStripeGet(sessionId?: string, utmContent?: string, utmCampaign?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.billingReturnFromStripeGet(sessionId, utmContent, utmCampaign, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
    /**
     * 
     * @param {string} [plan] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingChangePlanGet(plan?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingChangePlanGet(plan, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingCustomerPortalGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingCustomerPortalGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {DowngradePlanReasonViewModel} [downgradePlanReasonViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDowngradePlanReasonPost(apiVersion?: string, downgradePlanReasonViewModel?: DowngradePlanReasonViewModel, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDowngradePlanReasonPost(apiVersion, downgradePlanReasonViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sessionId] 
     * @param {string} [utmContent] 
     * @param {string} [utmCampaign] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingReturnFromStripeGet(sessionId?: string, utmContent?: string, utmCampaign?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingReturnFromStripeGet(sessionId, utmContent, utmCampaign, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
