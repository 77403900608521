/* tslint:disable */
/* eslint-disable */
/**
 * LoudHippo API
 * An API for LoudHippo Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@loudhippo.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SurveyJsonPayloadPageElement } from '../models';
// @ts-ignore
import { SurveyJsonViewModel } from '../models';
// @ts-ignore
import { SurveyListItemViewModel } from '../models';
// @ts-ignore
import { SurveyQuestionAnalyticsPageViewModel } from '../models';
// @ts-ignore
import { SurveyQuestionListItem } from '../models';
// @ts-ignore
import { SurveyResponseListItemViewModelListAndCountResult } from '../models';
// @ts-ignore
import { SurveyResponseSaveViewModel } from '../models';
// @ts-ignore
import { SurveyResponseViewModel } from '../models';
// @ts-ignore
import { SurveyStylesViewModel } from '../models';
// @ts-ignore
import { SurveyTopLevelStatsModel } from '../models';
// @ts-ignore
import { SurveyViewModel } from '../models';
/**
 * SurveyApi - axios parameter creator
 * @export
 */
export const SurveyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAccountstyleCssGet: async (accountId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/accountstyle.css`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAppendTemplateToSurveyIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveyAppendTemplateToSurveyIdGet', 'id', id)
            const localVarPath = `/Survey/AppendTemplateToSurvey/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCloneByTemplateIdIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveyCloneByTemplateIdIdGet', 'id', id)
            const localVarPath = `/Survey/CloneByTemplateId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyExportQuestionResponsesToCsvGet: async (questionNameId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/ExportQuestionResponsesToCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionNameId !== undefined) {
                localVarQueryParameter['questionNameId'] = questionNameId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetByAccountGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetByAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetByIdIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveyGetByIdIdGet', 'id', id)
            const localVarPath = `/Survey/GetById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiKey] 
         * @param {string} [platform] 
         * @param {string} [sourceOrderId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetDefaultByApiKeyAndOrderIdGet: async (apiKey?: string, platform?: string, sourceOrderId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetDefaultByApiKeyAndOrderId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiKey !== undefined) {
                localVarQueryParameter['apiKey'] = apiKey;
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            if (sourceOrderId !== undefined) {
                localVarQueryParameter['sourceOrderId'] = sourceOrderId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiKey] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetDefaultByApiKeyGet: async (apiKey?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetDefaultByApiKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiKey !== undefined) {
                localVarQueryParameter['apiKey'] = apiKey;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shopDomain 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetDefaultByShopifyDomainShopDomainGet: async (shopDomain: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopDomain' is not null or undefined
            assertParamExists('surveyGetDefaultByShopifyDomainShopDomainGet', 'shopDomain', shopDomain)
            const localVarPath = `/Survey/GetDefaultByShopifyDomain/{shopDomain}`
                .replace(`{${"shopDomain"}}`, encodeURIComponent(String(shopDomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetQuestionAnalyticsPageViewModelGet: async (questionNameId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetQuestionAnalyticsPageViewModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionNameId !== undefined) {
                localVarQueryParameter['questionNameId'] = questionNameId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetQuestionAnalyticsPageViewModelWithDateFiltersGet: async (questionNameId?: string, startDate?: string, endDate?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetQuestionAnalyticsPageViewModelWithDateFilters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionNameId !== undefined) {
                localVarQueryParameter['questionNameId'] = questionNameId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetQuestionElementGet: async (questionNameId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetQuestionElement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionNameId !== undefined) {
                localVarQueryParameter['questionNameId'] = questionNameId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [surveyId] 
         * @param {string} [platform] 
         * @param {string} [sourceOrderId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetResponseByPlatformAndSourceOrderIdGet: async (surveyId?: string, platform?: string, sourceOrderId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetResponseByPlatformAndSourceOrderId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['surveyId'] = surveyId;
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            if (sourceOrderId !== undefined) {
                localVarQueryParameter['sourceOrderId'] = sourceOrderId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetStylesGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetStyles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetSurveyQuestionListByApiKeyGet: async (key?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetSurveyQuestionListByApiKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetSurveyQuestionListForAccountByDateRangeGet: async (startDate?: string, endDate?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetSurveyQuestionListForAccountByDateRange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetSurveyQuestionListForAccountGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetSurveyQuestionListForAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetTopLevelStatsForAccountByDateRangeGet: async (startDate?: string, endDate?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetTopLevelStatsForAccountByDateRange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetTopLevelStatsForAccountGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/GetTopLevelStatsForAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyKeystyleCssGet: async (key?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/keystyle.css`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [status] 
         * @param {string} [categories] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyListGet: async (status?: string, categories?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (categories !== undefined) {
                localVarQueryParameter['categories'] = categories;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (isDesc !== undefined) {
                localVarQueryParameter['isDesc'] = isDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyPreviewShopifyOrderHtmlGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/PreviewShopifyOrderHtml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResponsesListByQuestionGet: async (key?: string, questionNameId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/ResponsesListByQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (questionNameId !== undefined) {
                localVarQueryParameter['questionNameId'] = questionNameId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResponsesListGet: async (questionNameId?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/ResponsesList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionNameId !== undefined) {
                localVarQueryParameter['questionNameId'] = questionNameId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (isDesc !== undefined) {
                localVarQueryParameter['isDesc'] = isDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyJsonViewModel} [surveyJsonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySavePost: async (apiVersion?: string, surveyJsonViewModel?: SurveyJsonViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyJsonViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyResponseSaveViewModel} [surveyResponseSaveViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySaveResponsePost: async (apiVersion?: string, surveyResponseSaveViewModel?: SurveyResponseSaveViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/SaveResponse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyResponseSaveViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyStylesViewModel} [surveyStylesViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySaveStylesPost: async (apiVersion?: string, surveyStylesViewModel?: SurveyStylesViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/SaveStyles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyStylesViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [shopDomain] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyStyleCssGet: async (shopDomain?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/style.css`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (shopDomain !== undefined) {
                localVarQueryParameter['shopDomain'] = shopDomain;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyToggleStatusByIdIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveyToggleStatusByIdIdGet', 'id', id)
            const localVarPath = `/Survey/ToggleStatusById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [imageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyUploadImagePost: async (apiVersion?: string, imageFile?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Survey/UploadImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


            if (imageFile !== undefined) { 
                localVarFormParams.append('imageFile', imageFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyApi - functional programming interface
 * @export
 */
export const SurveyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SurveyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAccountstyleCssGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyAccountstyleCssGet(accountId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAppendTemplateToSurveyIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyAppendTemplateToSurveyIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyCloneByTemplateIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyCloneByTemplateIdIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyExportQuestionResponsesToCsvGet(questionNameId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyExportQuestionResponsesToCsvGet(questionNameId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetByAccountGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetByAccountGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetByIdIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiKey] 
         * @param {string} [platform] 
         * @param {string} [sourceOrderId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetDefaultByApiKeyAndOrderIdGet(apiKey?: string, platform?: string, sourceOrderId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetDefaultByApiKeyAndOrderIdGet(apiKey, platform, sourceOrderId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiKey] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetDefaultByApiKeyGet(apiKey?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetDefaultByApiKeyGet(apiKey, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} shopDomain 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetDefaultByShopifyDomainShopDomainGet(shopDomain: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetDefaultByShopifyDomainShopDomainGet(shopDomain, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetQuestionAnalyticsPageViewModelGet(questionNameId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyQuestionAnalyticsPageViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetQuestionAnalyticsPageViewModelGet(questionNameId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetQuestionAnalyticsPageViewModelWithDateFiltersGet(questionNameId?: string, startDate?: string, endDate?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyQuestionAnalyticsPageViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetQuestionAnalyticsPageViewModelWithDateFiltersGet(questionNameId, startDate, endDate, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetQuestionElementGet(questionNameId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyJsonPayloadPageElement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetQuestionElementGet(questionNameId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [surveyId] 
         * @param {string} [platform] 
         * @param {string} [sourceOrderId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetResponseByPlatformAndSourceOrderIdGet(surveyId?: string, platform?: string, sourceOrderId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponseViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetResponseByPlatformAndSourceOrderIdGet(surveyId, platform, sourceOrderId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetStylesGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyStylesViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetStylesGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetSurveyQuestionListByApiKeyGet(key?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyQuestionListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetSurveyQuestionListByApiKeyGet(key, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetSurveyQuestionListForAccountByDateRangeGet(startDate?: string, endDate?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyQuestionListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetSurveyQuestionListForAccountByDateRangeGet(startDate, endDate, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetSurveyQuestionListForAccountGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyQuestionListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetSurveyQuestionListForAccountGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetTopLevelStatsForAccountByDateRangeGet(startDate?: string, endDate?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyTopLevelStatsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetTopLevelStatsForAccountByDateRangeGet(startDate, endDate, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyGetTopLevelStatsForAccountGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyTopLevelStatsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetTopLevelStatsForAccountGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyKeystyleCssGet(key?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyKeystyleCssGet(key, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [status] 
         * @param {string} [categories] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyListGet(status?: string, categories?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyListItemViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyListGet(status, categories, page, skip, limit, sortField, isDesc, search, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyPreviewShopifyOrderHtmlGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyPreviewShopifyOrderHtmlGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyResponsesListByQuestionGet(key?: string, questionNameId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponseListItemViewModelListAndCountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyResponsesListByQuestionGet(key, questionNameId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyResponsesListGet(questionNameId?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponseListItemViewModelListAndCountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyResponsesListGet(questionNameId, page, skip, limit, sortField, isDesc, search, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyJsonViewModel} [surveyJsonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveySavePost(apiVersion?: string, surveyJsonViewModel?: SurveyJsonViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveySavePost(apiVersion, surveyJsonViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyResponseSaveViewModel} [surveyResponseSaveViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveySaveResponsePost(apiVersion?: string, surveyResponseSaveViewModel?: SurveyResponseSaveViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveySaveResponsePost(apiVersion, surveyResponseSaveViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyStylesViewModel} [surveyStylesViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveySaveStylesPost(apiVersion?: string, surveyStylesViewModel?: SurveyStylesViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveySaveStylesPost(apiVersion, surveyStylesViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [shopDomain] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyStyleCssGet(shopDomain?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyStyleCssGet(shopDomain, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyToggleStatusByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyToggleStatusByIdIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [imageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyUploadImagePost(apiVersion?: string, imageFile?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyUploadImagePost(apiVersion, imageFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SurveyApi - factory interface
 * @export
 */
export const SurveyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SurveyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAccountstyleCssGet(accountId?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.surveyAccountstyleCssGet(accountId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAppendTemplateToSurveyIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SurveyViewModel> {
            return localVarFp.surveyAppendTemplateToSurveyIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCloneByTemplateIdIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SurveyViewModel> {
            return localVarFp.surveyCloneByTemplateIdIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyExportQuestionResponsesToCsvGet(questionNameId?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.surveyExportQuestionResponsesToCsvGet(questionNameId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetByAccountGet(apiVersion?: string, options?: any): AxiosPromise<SurveyViewModel> {
            return localVarFp.surveyGetByAccountGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetByIdIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SurveyViewModel> {
            return localVarFp.surveyGetByIdIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiKey] 
         * @param {string} [platform] 
         * @param {string} [sourceOrderId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetDefaultByApiKeyAndOrderIdGet(apiKey?: string, platform?: string, sourceOrderId?: string, apiVersion?: string, options?: any): AxiosPromise<SurveyViewModel> {
            return localVarFp.surveyGetDefaultByApiKeyAndOrderIdGet(apiKey, platform, sourceOrderId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiKey] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetDefaultByApiKeyGet(apiKey?: string, apiVersion?: string, options?: any): AxiosPromise<SurveyViewModel> {
            return localVarFp.surveyGetDefaultByApiKeyGet(apiKey, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shopDomain 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetDefaultByShopifyDomainShopDomainGet(shopDomain: string, apiVersion?: string, options?: any): AxiosPromise<SurveyViewModel> {
            return localVarFp.surveyGetDefaultByShopifyDomainShopDomainGet(shopDomain, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetQuestionAnalyticsPageViewModelGet(questionNameId?: string, apiVersion?: string, options?: any): AxiosPromise<SurveyQuestionAnalyticsPageViewModel> {
            return localVarFp.surveyGetQuestionAnalyticsPageViewModelGet(questionNameId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetQuestionAnalyticsPageViewModelWithDateFiltersGet(questionNameId?: string, startDate?: string, endDate?: string, apiVersion?: string, options?: any): AxiosPromise<SurveyQuestionAnalyticsPageViewModel> {
            return localVarFp.surveyGetQuestionAnalyticsPageViewModelWithDateFiltersGet(questionNameId, startDate, endDate, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetQuestionElementGet(questionNameId?: string, apiVersion?: string, options?: any): AxiosPromise<SurveyJsonPayloadPageElement> {
            return localVarFp.surveyGetQuestionElementGet(questionNameId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [surveyId] 
         * @param {string} [platform] 
         * @param {string} [sourceOrderId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetResponseByPlatformAndSourceOrderIdGet(surveyId?: string, platform?: string, sourceOrderId?: string, apiVersion?: string, options?: any): AxiosPromise<SurveyResponseViewModel> {
            return localVarFp.surveyGetResponseByPlatformAndSourceOrderIdGet(surveyId, platform, sourceOrderId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetStylesGet(apiVersion?: string, options?: any): AxiosPromise<SurveyStylesViewModel> {
            return localVarFp.surveyGetStylesGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetSurveyQuestionListByApiKeyGet(key?: string, apiVersion?: string, options?: any): AxiosPromise<Array<SurveyQuestionListItem>> {
            return localVarFp.surveyGetSurveyQuestionListByApiKeyGet(key, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetSurveyQuestionListForAccountByDateRangeGet(startDate?: string, endDate?: string, apiVersion?: string, options?: any): AxiosPromise<Array<SurveyQuestionListItem>> {
            return localVarFp.surveyGetSurveyQuestionListForAccountByDateRangeGet(startDate, endDate, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetSurveyQuestionListForAccountGet(apiVersion?: string, options?: any): AxiosPromise<Array<SurveyQuestionListItem>> {
            return localVarFp.surveyGetSurveyQuestionListForAccountGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetTopLevelStatsForAccountByDateRangeGet(startDate?: string, endDate?: string, apiVersion?: string, options?: any): AxiosPromise<SurveyTopLevelStatsModel> {
            return localVarFp.surveyGetTopLevelStatsForAccountByDateRangeGet(startDate, endDate, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyGetTopLevelStatsForAccountGet(apiVersion?: string, options?: any): AxiosPromise<SurveyTopLevelStatsModel> {
            return localVarFp.surveyGetTopLevelStatsForAccountGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyKeystyleCssGet(key?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.surveyKeystyleCssGet(key, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [status] 
         * @param {string} [categories] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyListGet(status?: string, categories?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: any): AxiosPromise<Array<SurveyListItemViewModel>> {
            return localVarFp.surveyListGet(status, categories, page, skip, limit, sortField, isDesc, search, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyPreviewShopifyOrderHtmlGet(apiVersion?: string, options?: any): AxiosPromise<string> {
            return localVarFp.surveyPreviewShopifyOrderHtmlGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [questionNameId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResponsesListByQuestionGet(key?: string, questionNameId?: string, apiVersion?: string, options?: any): AxiosPromise<SurveyResponseListItemViewModelListAndCountResult> {
            return localVarFp.surveyResponsesListByQuestionGet(key, questionNameId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [questionNameId] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResponsesListGet(questionNameId?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: any): AxiosPromise<SurveyResponseListItemViewModelListAndCountResult> {
            return localVarFp.surveyResponsesListGet(questionNameId, page, skip, limit, sortField, isDesc, search, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyJsonViewModel} [surveyJsonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySavePost(apiVersion?: string, surveyJsonViewModel?: SurveyJsonViewModel, options?: any): AxiosPromise<string> {
            return localVarFp.surveySavePost(apiVersion, surveyJsonViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyResponseSaveViewModel} [surveyResponseSaveViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySaveResponsePost(apiVersion?: string, surveyResponseSaveViewModel?: SurveyResponseSaveViewModel, options?: any): AxiosPromise<string> {
            return localVarFp.surveySaveResponsePost(apiVersion, surveyResponseSaveViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyStylesViewModel} [surveyStylesViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySaveStylesPost(apiVersion?: string, surveyStylesViewModel?: SurveyStylesViewModel, options?: any): AxiosPromise<string> {
            return localVarFp.surveySaveStylesPost(apiVersion, surveyStylesViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [shopDomain] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyStyleCssGet(shopDomain?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.surveyStyleCssGet(shopDomain, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyToggleStatusByIdIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.surveyToggleStatusByIdIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [imageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyUploadImagePost(apiVersion?: string, imageFile?: any, options?: any): AxiosPromise<string> {
            return localVarFp.surveyUploadImagePost(apiVersion, imageFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SurveyApi - object-oriented interface
 * @export
 * @class SurveyApi
 * @extends {BaseAPI}
 */
export class SurveyApi extends BaseAPI {
    /**
     * 
     * @param {string} [accountId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyAccountstyleCssGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyAccountstyleCssGet(accountId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyAppendTemplateToSurveyIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyAppendTemplateToSurveyIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyCloneByTemplateIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyCloneByTemplateIdIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [questionNameId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyExportQuestionResponsesToCsvGet(questionNameId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyExportQuestionResponsesToCsvGet(questionNameId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetByAccountGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetByAccountGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetByIdIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiKey] 
     * @param {string} [platform] 
     * @param {string} [sourceOrderId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetDefaultByApiKeyAndOrderIdGet(apiKey?: string, platform?: string, sourceOrderId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetDefaultByApiKeyAndOrderIdGet(apiKey, platform, sourceOrderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiKey] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetDefaultByApiKeyGet(apiKey?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetDefaultByApiKeyGet(apiKey, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shopDomain 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetDefaultByShopifyDomainShopDomainGet(shopDomain: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetDefaultByShopifyDomainShopDomainGet(shopDomain, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [questionNameId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetQuestionAnalyticsPageViewModelGet(questionNameId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetQuestionAnalyticsPageViewModelGet(questionNameId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [questionNameId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetQuestionAnalyticsPageViewModelWithDateFiltersGet(questionNameId?: string, startDate?: string, endDate?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetQuestionAnalyticsPageViewModelWithDateFiltersGet(questionNameId, startDate, endDate, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [questionNameId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetQuestionElementGet(questionNameId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetQuestionElementGet(questionNameId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [surveyId] 
     * @param {string} [platform] 
     * @param {string} [sourceOrderId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetResponseByPlatformAndSourceOrderIdGet(surveyId?: string, platform?: string, sourceOrderId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetResponseByPlatformAndSourceOrderIdGet(surveyId, platform, sourceOrderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetStylesGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetStylesGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [key] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetSurveyQuestionListByApiKeyGet(key?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetSurveyQuestionListByApiKeyGet(key, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetSurveyQuestionListForAccountByDateRangeGet(startDate?: string, endDate?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetSurveyQuestionListForAccountByDateRangeGet(startDate, endDate, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetSurveyQuestionListForAccountGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetSurveyQuestionListForAccountGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetTopLevelStatsForAccountByDateRangeGet(startDate?: string, endDate?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetTopLevelStatsForAccountByDateRangeGet(startDate, endDate, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyGetTopLevelStatsForAccountGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyGetTopLevelStatsForAccountGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [key] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyKeystyleCssGet(key?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyKeystyleCssGet(key, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [status] 
     * @param {string} [categories] 
     * @param {number} [page] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [sortField] 
     * @param {boolean} [isDesc] 
     * @param {string} [search] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyListGet(status?: string, categories?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyListGet(status, categories, page, skip, limit, sortField, isDesc, search, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyPreviewShopifyOrderHtmlGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyPreviewShopifyOrderHtmlGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [key] 
     * @param {string} [questionNameId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyResponsesListByQuestionGet(key?: string, questionNameId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyResponsesListByQuestionGet(key, questionNameId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [questionNameId] 
     * @param {number} [page] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [sortField] 
     * @param {boolean} [isDesc] 
     * @param {string} [search] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyResponsesListGet(questionNameId?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyResponsesListGet(questionNameId, page, skip, limit, sortField, isDesc, search, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {SurveyJsonViewModel} [surveyJsonViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveySavePost(apiVersion?: string, surveyJsonViewModel?: SurveyJsonViewModel, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveySavePost(apiVersion, surveyJsonViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {SurveyResponseSaveViewModel} [surveyResponseSaveViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveySaveResponsePost(apiVersion?: string, surveyResponseSaveViewModel?: SurveyResponseSaveViewModel, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveySaveResponsePost(apiVersion, surveyResponseSaveViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {SurveyStylesViewModel} [surveyStylesViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveySaveStylesPost(apiVersion?: string, surveyStylesViewModel?: SurveyStylesViewModel, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveySaveStylesPost(apiVersion, surveyStylesViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [shopDomain] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyStyleCssGet(shopDomain?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyStyleCssGet(shopDomain, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyToggleStatusByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyToggleStatusByIdIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {any} [imageFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyUploadImagePost(apiVersion?: string, imageFile?: any, options?: AxiosRequestConfig) {
        return SurveyApiFp(this.configuration).surveyUploadImagePost(apiVersion, imageFile, options).then((request) => request(this.axios, this.basePath));
    }
}
