/* tslint:disable */
/* eslint-disable */
/**
 * LoudHippo API
 * An API for LoudHippo Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@loudhippo.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SurveyTemplateJsonViewModel } from '../models';
// @ts-ignore
import { SurveyTemplateListItemViewModel } from '../models';
// @ts-ignore
import { SurveyTemplateViewModel } from '../models';
/**
 * SurveyTemplateApi - axios parameter creator
 * @export
 */
export const SurveyTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyTemplateGetByIdIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveyTemplateGetByIdIdGet', 'id', id)
            const localVarPath = `/SurveyTemplate/GetById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [status] 
         * @param {string} [categories] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyTemplateListGet: async (status?: string, categories?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SurveyTemplate/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (categories !== undefined) {
                localVarQueryParameter['categories'] = categories;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (isDesc !== undefined) {
                localVarQueryParameter['isDesc'] = isDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyTemplateJsonViewModel} [surveyTemplateJsonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyTemplateSavePost: async (apiVersion?: string, surveyTemplateJsonViewModel?: SurveyTemplateJsonViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SurveyTemplate/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyTemplateJsonViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [imageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyTemplateUploadTemplateImagePost: async (apiVersion?: string, imageFile?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SurveyTemplate/UploadTemplateImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


            if (imageFile !== undefined) { 
                localVarFormParams.append('imageFile', imageFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyTemplateApi - functional programming interface
 * @export
 */
export const SurveyTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SurveyTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyTemplateGetByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyTemplateViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyTemplateGetByIdIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [status] 
         * @param {string} [categories] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyTemplateListGet(status?: string, categories?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyTemplateListItemViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyTemplateListGet(status, categories, page, skip, limit, sortField, isDesc, search, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyTemplateJsonViewModel} [surveyTemplateJsonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyTemplateSavePost(apiVersion?: string, surveyTemplateJsonViewModel?: SurveyTemplateJsonViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyTemplateSavePost(apiVersion, surveyTemplateJsonViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [imageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyTemplateUploadTemplateImagePost(apiVersion?: string, imageFile?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyTemplateUploadTemplateImagePost(apiVersion, imageFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SurveyTemplateApi - factory interface
 * @export
 */
export const SurveyTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SurveyTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyTemplateGetByIdIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SurveyTemplateViewModel> {
            return localVarFp.surveyTemplateGetByIdIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [status] 
         * @param {string} [categories] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyTemplateListGet(status?: string, categories?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: any): AxiosPromise<Array<SurveyTemplateListItemViewModel>> {
            return localVarFp.surveyTemplateListGet(status, categories, page, skip, limit, sortField, isDesc, search, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SurveyTemplateJsonViewModel} [surveyTemplateJsonViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyTemplateSavePost(apiVersion?: string, surveyTemplateJsonViewModel?: SurveyTemplateJsonViewModel, options?: any): AxiosPromise<string> {
            return localVarFp.surveyTemplateSavePost(apiVersion, surveyTemplateJsonViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [imageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyTemplateUploadTemplateImagePost(apiVersion?: string, imageFile?: any, options?: any): AxiosPromise<string> {
            return localVarFp.surveyTemplateUploadTemplateImagePost(apiVersion, imageFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SurveyTemplateApi - object-oriented interface
 * @export
 * @class SurveyTemplateApi
 * @extends {BaseAPI}
 */
export class SurveyTemplateApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyTemplateApi
     */
    public surveyTemplateGetByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyTemplateApiFp(this.configuration).surveyTemplateGetByIdIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [status] 
     * @param {string} [categories] 
     * @param {number} [page] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [sortField] 
     * @param {boolean} [isDesc] 
     * @param {string} [search] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyTemplateApi
     */
    public surveyTemplateListGet(status?: string, categories?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return SurveyTemplateApiFp(this.configuration).surveyTemplateListGet(status, categories, page, skip, limit, sortField, isDesc, search, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {SurveyTemplateJsonViewModel} [surveyTemplateJsonViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyTemplateApi
     */
    public surveyTemplateSavePost(apiVersion?: string, surveyTemplateJsonViewModel?: SurveyTemplateJsonViewModel, options?: AxiosRequestConfig) {
        return SurveyTemplateApiFp(this.configuration).surveyTemplateSavePost(apiVersion, surveyTemplateJsonViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {any} [imageFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyTemplateApi
     */
    public surveyTemplateUploadTemplateImagePost(apiVersion?: string, imageFile?: any, options?: AxiosRequestConfig) {
        return SurveyTemplateApiFp(this.configuration).surveyTemplateUploadTemplateImagePost(apiVersion, imageFile, options).then((request) => request(this.axios, this.basePath));
    }
}
