/* tslint:disable */
/* eslint-disable */
/**
 * LoudHippo API
 * An API for LoudHippo Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@loudhippo.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompleteProfileViewModel } from '../models';
// @ts-ignore
import { ProfileViewModel } from '../models';
// @ts-ignore
import { SetupChecklistViewModel } from '../models';
// @ts-ignore
import { UpdateBusinessInformationViewModel } from '../models';
// @ts-ignore
import { UpdateContactInformationViewModel } from '../models';
// @ts-ignore
import { UpdateNotificationsViewModel } from '../models';
/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CompleteProfileViewModel} [completeProfileViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileCompleteProfilePost: async (apiVersion?: string, completeProfileViewModel?: CompleteProfileViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/CompleteProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeProfileViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileProfileGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/Profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileSetupChecklistGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/SetupChecklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [integration] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileSubmitAppStoreReviewPost: async (integration?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/SubmitAppStoreReview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (integration !== undefined) {
                localVarQueryParameter['integration'] = integration;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [rating] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileSubmitFeedbackRatingPost: async (rating?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/SubmitFeedbackRating`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rating !== undefined) {
                localVarQueryParameter['rating'] = rating;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UpdateBusinessInformationViewModel} [updateBusinessInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateBusinessInformationPost: async (apiVersion?: string, updateBusinessInformationViewModel?: UpdateBusinessInformationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/UpdateBusinessInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBusinessInformationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UpdateContactInformationViewModel} [updateContactInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateContactInformationPost: async (apiVersion?: string, updateContactInformationViewModel?: UpdateContactInformationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/UpdateContactInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContactInformationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UpdateNotificationsViewModel} [updateNotificationsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateNotificationsPost: async (apiVersion?: string, updateNotificationsViewModel?: UpdateNotificationsViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/UpdateNotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationsViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CompleteProfileViewModel} [completeProfileViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileCompleteProfilePost(apiVersion?: string, completeProfileViewModel?: CompleteProfileViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileCompleteProfilePost(apiVersion, completeProfileViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileProfileGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileProfileGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileSetupChecklistGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetupChecklistViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileSetupChecklistGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [integration] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileSubmitAppStoreReviewPost(integration?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileSubmitAppStoreReviewPost(integration, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [rating] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileSubmitFeedbackRatingPost(rating?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileSubmitFeedbackRatingPost(rating, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UpdateBusinessInformationViewModel} [updateBusinessInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileUpdateBusinessInformationPost(apiVersion?: string, updateBusinessInformationViewModel?: UpdateBusinessInformationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileUpdateBusinessInformationPost(apiVersion, updateBusinessInformationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UpdateContactInformationViewModel} [updateContactInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileUpdateContactInformationPost(apiVersion?: string, updateContactInformationViewModel?: UpdateContactInformationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileUpdateContactInformationPost(apiVersion, updateContactInformationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UpdateNotificationsViewModel} [updateNotificationsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileUpdateNotificationsPost(apiVersion?: string, updateNotificationsViewModel?: UpdateNotificationsViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileUpdateNotificationsPost(apiVersion, updateNotificationsViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CompleteProfileViewModel} [completeProfileViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileCompleteProfilePost(apiVersion?: string, completeProfileViewModel?: CompleteProfileViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.profileCompleteProfilePost(apiVersion, completeProfileViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileProfileGet(apiVersion?: string, options?: any): AxiosPromise<ProfileViewModel> {
            return localVarFp.profileProfileGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileSetupChecklistGet(apiVersion?: string, options?: any): AxiosPromise<SetupChecklistViewModel> {
            return localVarFp.profileSetupChecklistGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [integration] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileSubmitAppStoreReviewPost(integration?: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.profileSubmitAppStoreReviewPost(integration, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [rating] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileSubmitFeedbackRatingPost(rating?: number, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.profileSubmitFeedbackRatingPost(rating, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UpdateBusinessInformationViewModel} [updateBusinessInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateBusinessInformationPost(apiVersion?: string, updateBusinessInformationViewModel?: UpdateBusinessInformationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.profileUpdateBusinessInformationPost(apiVersion, updateBusinessInformationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UpdateContactInformationViewModel} [updateContactInformationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateContactInformationPost(apiVersion?: string, updateContactInformationViewModel?: UpdateContactInformationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.profileUpdateContactInformationPost(apiVersion, updateContactInformationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UpdateNotificationsViewModel} [updateNotificationsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateNotificationsPost(apiVersion?: string, updateNotificationsViewModel?: UpdateNotificationsViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.profileUpdateNotificationsPost(apiVersion, updateNotificationsViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {CompleteProfileViewModel} [completeProfileViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileCompleteProfilePost(apiVersion?: string, completeProfileViewModel?: CompleteProfileViewModel, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileCompleteProfilePost(apiVersion, completeProfileViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileProfileGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileProfileGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileSetupChecklistGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileSetupChecklistGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [integration] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileSubmitAppStoreReviewPost(integration?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileSubmitAppStoreReviewPost(integration, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [rating] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileSubmitFeedbackRatingPost(rating?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileSubmitFeedbackRatingPost(rating, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {UpdateBusinessInformationViewModel} [updateBusinessInformationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileUpdateBusinessInformationPost(apiVersion?: string, updateBusinessInformationViewModel?: UpdateBusinessInformationViewModel, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileUpdateBusinessInformationPost(apiVersion, updateBusinessInformationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {UpdateContactInformationViewModel} [updateContactInformationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileUpdateContactInformationPost(apiVersion?: string, updateContactInformationViewModel?: UpdateContactInformationViewModel, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileUpdateContactInformationPost(apiVersion, updateContactInformationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {UpdateNotificationsViewModel} [updateNotificationsViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileUpdateNotificationsPost(apiVersion?: string, updateNotificationsViewModel?: UpdateNotificationsViewModel, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileUpdateNotificationsPost(apiVersion, updateNotificationsViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}
